<template>
  <v-app>
    <v-app-bar app dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title
        >NOVA<strong>GREEN </strong>
        <router-link to="/" tag="span" style="cursor: pointer"> </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only"> </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-switch
        v-model="dark"
        class="pa-0 mt-6"
        @change="changetheme(dark)"
      ></v-switch>
      <v-btn icon @click="logout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app width="300">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ me.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ me.email }} </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <template v-for="item in childrensItem(menu)">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"> </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.to"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="child.text"> </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.to">
            <v-list-item-action>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer padless app>
      <v-col class="text-center" cols="12">
        <strong> {{ me.name }}</strong> connecté depuis
        {{ new Date().toLocaleString() }} —
        <strong>NOVAGREEN</strong>
      </v-col>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        centered
        color="green lighten-2"
      >
        {{ snackbar_text }}
      </v-snackbar>
    </v-footer>
  </v-app>
</template>

<script>
const DARK = "darktheme";
import { onLogout } from "../vue-apollo.js";
import ME from "../graphql/ME.gql";

export default {
  data: () => ({
    dark: false,
    snackbar: true,
    snackbar_text: "Connexion etablie avec succes",
    snackbar_timeout: 2000,
    drawer: null,
    me: {},
    global_search: null,
    selectedItem: 0,
    items: [
      {
        text: "Commercial",
        icon: "mdi-view-dashboard",
        to: "/listedevis",
        menu: "0",
        model: true,
        children: [
          {
            text: "Ventes & Achats",
            icon: "mdi-account-multiple",

            model: true,
            children: [
              {
                text: "Devis",
                icon: "mdi-view-dashboard",
                to: "/listedevis",
              },
              {
                text: "Clients",
                icon: "mdi-view-dashboard",
                to: "/clients",
              },
              {
                text: "Livraisons",
                icon: "mdi-view-dashboard",
                to: "/livraison",
              },
              {
                text: "Facturations",
                icon: "mdi-view-dashboard",
                to: "/facturations",
              },
              {
                text: "Recouvrements",
                icon: "mdi-view-dashboard",
                to: "/recouvrements",
              },
            ],
          },
          {
            text: "Dictionnaire",
            icon: "mdi-view-dashboard",
            to: "/dictionnaire",
          },
        ],
      },
    ],
    menu: "0",
  }),

  created() {
    this.initialize();
  },
  computed: {},
  methods: {
    changetheme(value) {
      if (value) localStorage.setItem(DARK, "1");
      else localStorage.setItem(DARK, "0");
      this.$vuetify.theme.dark = value;
    },
    childrensItem(menu) {
      return this.items[this.items.findIndex((h) => h.menu === menu)].children;
    },
    initialize() {
      if (localStorage.getItem(DARK) != null) {
        let dark1 = localStorage.getItem(DARK);
        if (dark1 == "1") this.dark = true;
        else this.dark = false;
      }
      this.$vuetify.theme.dark = this.dark;
      this.$apollo

        .query({
          query: ME,
        })
        .then((data) => {
          this.me = data.data.me;
          this.$store.dispatch("ActualUser", data.data.me);

          //console.log(data.data.me);
        });
    },

    set_search() {
      this.$store.dispatch("set_search_text", this.global_search);
    },

    logout() {
      onLogout(this.$apollo.provider.defaultClient);
      this.$router.push("/");
    },
  },
};
</script>
